import { createAsyncThunk } from '@reduxjs/toolkit'
import { issClient } from '../../utils/HttpClients/index'
import { camelCaseGlobalSettingsResponse, getResponseData } from '../../utils/HttpClients/helpers'
import { Buffer } from 'buffer'
import i18n from '../../i18n'

/**
 * Return all data of form with given requestId.
 *
 * @param {Number} requestId
 * @returns {*}
 */
export const loadFormDetails = createAsyncThunk('form/fetchFormDetails', async (requestId) => {
    const response = await issClient
        .get(`/form/${requestId}`)
        .then(getResponseData)
        .then(camelCaseGlobalSettingsResponse)

    return {
        requestId,
        data: response.data,
    }
})

/**
 * Load photo for a given requestId and transform it to base64.
 *
 * @param {Number} requestId
 * @returns {*}
 */
export const loadFormPhoto = createAsyncThunk('form/fetchFormPhoto', async (requestId) => {
    const response = await issClient
        .get(`/form/${requestId}/photo`, { responseType: 'arraybuffer' })
        .then((response) => Buffer.from(response.data, 'binary').toString('base64'))

    return response
})

/**
 * Store updated form data to backend.
 *
 * @params {Object} formData
 * @returns {*}
 */
export const storePersonalInformation = createAsyncThunk(
    'form/storePersonalInformation',
    async ({ phoneNumber, gender }, { getState, rejectWithValue }) => {
        const { requestId } = getState().form
        const payload = {
            phone_number: phoneNumber,
            gender: gender,
        }

        try {
            const response = await issClient
                .post(`/form/${requestId}`, payload)
                .then(getResponseData)
                .then(camelCaseGlobalSettingsResponse)

            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

/**
 * Store granted agreements to backend.
 *
 * @params {Object} formData
 * @returns {*}
 */
export const storeAgreements = createAsyncThunk(
    'form/storeAgreements',
    async ({ isMarketingGranted }, { getState, rejectWithValue }) => {
        const { requestId } = getState().form
        const payload = {
            marketing_agreement: isMarketingGranted,
        }

        try {
            const response = await issClient
                .post(`/form/${requestId}/agreements`, payload)
                .then(getResponseData)
                .then(camelCaseGlobalSettingsResponse)

            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

/**
 * Store selected payment method to backend.
 *
 * @params {Number} providerId
 * @returns {*}
 */
export const storePaymentMethod = createAsyncThunk(
    'form/storePaymentMethod',
    async (providerId, { getState, rejectWithValue }) => {
        const { requestId } = getState().form

        try {
            const response = await issClient
                .post(`/form/${requestId}/payment`, {
                    provider_id: parseInt(providerId),
                    lang: i18n.language,
                })
                .then(getResponseData)
                .then(camelCaseGlobalSettingsResponse)

            return {
                requestId,
                providerId: parseInt(providerId),
                data: response.data,
            }
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)
