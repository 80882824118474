import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store/configureStore'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import './i18n/index'

// import {
//     BrowserRouter,
//     useLocation,
//     useNavigationType,
//     createRoutesFromChildren,
//     matchRoutes,
// } from 'react-router-dom'

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
        new BrowserTracing({
            // routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            //     React.useEffect,
            //     useLocation,
            //     useNavigationType,
            //     createRoutesFromChildren,
            //     matchRoutes
            // ),
            // tracingOrigins: [
            //     'localhost',
            //     'pm.test.gts.bootiq-devel.eu',
            //     'portal.test.aliveplatform.com',
            //     'portal.uat.aliveplatform.com',
            //     /^\//,
            // ],
        }),
    ],
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
})

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
    console.log = () => {} // eslint-disable-line no-console
    console.error = () => {} // eslint-disable-line no-console
    console.debug = () => {} // eslint-disable-line no-console
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    // <React.StrictMode>
    <Sentry.ErrorBoundary fallback={'Error occurred, we are sorry.'}>
        <Provider store={store}>
            <App />
        </Provider>
    </Sentry.ErrorBoundary>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
